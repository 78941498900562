.App {
  text-align: center;
  background-color: #E2FEFE; /* Or any other background color */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 800px; /* Adjust width as needed */
  padding: 50px;
  background-color: #E2FEFE; /* Background color for the container */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add shadow for a subtle depth effect */
  border-radius: 8px; /* Optional: round corners */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  max-width: 100%; /* Ensures the logo is responsive */
  height: auto; /* Maintains aspect ratio */
}

.title {
  margin-top: 16px; /* Space between logo and title */
  color: #575770; /* Title color */
  font-size: 1.5em; /* Title size */
  margin-bottom: 32px; /* Space between title and button */
}

.login-button {
  background-color: #2c3e50; /* GitHub color or any color you prefer */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px; /* Rounded corners for the button */
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for button hover effect */
}

.login-button:hover {
  background-color: #34495e; /* Darken button on hover */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.thank-you-container {
  text-align: center;
  padding: 20px;
}

.logout-button {
  background-color: #f44336; /* Red color for logout button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px; /* Rounded corners for the button */
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for button hover effect */
  margin-top: 20px; /* Space from the message above */
}

.logout-button:hover {
  background-color: #d32f2f; /* Darken button on hover */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
